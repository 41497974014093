import copy from "copy-to-clipboard";
import {NzMessageService} from "ng-zorro-antd/message";
import {projectMessages} from "@app/shared/constants/project-messages";

const isSafari = () => {
  let isSafari = false;
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    isSafari = true;
  }

  return isSafari;
}

export const copyToClipboard = (text: string, nzMessageService: NzMessageService | null = null) => {
  const result = copy(text);

  if (result) {
    nzMessageService?.info(projectMessages.getTmUrl);
  } else {
    if (!isSafari()) {
      nzMessageService?.error(projectMessages.copyClpFailed);
    }
  }

  /* // Function to write text to clipboard
   const writeTextToClipboard = () => {
     navigator.clipboard.writeText(text)
       .then(() => {
         nzMessageService?.info(projectMessages.getTmUrl);
       })
       .catch(() => {
         nzMessageService?.error(projectMessages.copyClpFailed);
       });
   };

   // Check if document is focused
   if (document.hasFocus()) {
     writeTextToClipboard();
   } else {
     // Add a focus event listener to attempt copying again once the document gains focus
     const onFocus = () => {
       writeTextToClipboard();
       // Remove the event listener after the text has been copied
       window.removeEventListener('focus', onFocus);
     };

     // Add the event listener
     window.addEventListener('focus', onFocus);
     // Request focus
     window.focus();
   }*/
}

